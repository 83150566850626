jQuery(document).ready(function($) {
	// Mobile menu
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($( ".mobile-navigation-menu" ).hasClass( "mm-opened" )) {
			$(".mobile-navigation-menu").data( "mmenu" ).close();
		}
		else {
			$(".mobile-navigation-menu").data( "mmenu" ).open();
		}
	});

	// Fit Vids
	if ($("body").innerWidth() <= 767 ) {
		$("#content").fitVids({ ignore: "nofit" });
	}

	// Remove border form images that are link
	$( "a" ).has( "img" ).css( "border-bottom", 0 );
});
